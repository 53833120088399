import { combineReducers } from 'redux';

import groupReducer from './group/group.reducer';
import contactReducer from './contact/contact.reducer';
import settingsReducer from './settings/settings.reducer';
import shopReducer from './shop/shop.reducer';

export default combineReducers({
    group: groupReducer,
    contact: contactReducer,
    settings: settingsReducer,
    shop: shopReducer
});