import contact from './contact/contact.middleware';
import group from './group/group.middleware';
import settings from './settings/settings.middleware';
import shop from './shop/shop.middleware';

export default [
    contact,
    group,
    settings,
    shop
]