import { SHOP_INIT, SHOP_CART_REMOVE, SHOP_CART_UPDATE, SHOP_ORDER_COMPLETE, SHOP_UPDATE } from "./shop.actions";

const initialShop = {
    cart: {
        items: [],
        totalPriceVatExcluded: 0,
        totalPrice: 0,

    },
    categories: []
};


const shopReducer = (state = initialShop, action) => {
    switch (action.type) {
        case SHOP_UPDATE:
            return {
                ...state,
                [action.name]: action.value
            };
        case SHOP_CART_UPDATE:
            return {
                ...state,
                [action.name]: action.value
            };
        case SHOP_CART_REMOVE:
            return {
                ...state,
                cart: null
            };
        case SHOP_ORDER_COMPLETE:
            return {
                ...state,
                [action.name]: initialShop.cart
            };
        case SHOP_INIT:
            return initialShop;
        default:
            return state;
    }
}

export default shopReducer;